<template>
    <div>
        <div class="spinner" v-show="spin"><div><div></div></div></div>
    </div>
</template>
<script>

export default {
    name: "Spinner",
    props: {
        spin: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
           
        };
    }
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>